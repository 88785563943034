<template>
  <div class="projectDetails">
    <img :src="details.serveImgUrl" alt="" class="serveImg" />
    <img src="@/assets/img/home.png" alt="" class="homePng" @click="$router.push({path:'/'})" v-if="$route.query.from=='pageShare'">
    <div class="information-div">
      <p class="name">{{ details.serveName }}</p>
      <p class="price-p">
        <span class="price">￥{{ details.servePrice }}</span>
        <span class="VipPrice"
          ><img
            src="../../assets/img/vip_icon.png"
            alt=""
            class="vipIcon"
          />￥{{ details.serveVipPrice }}</span
        >
      </p>
      <p class="time">
        <van-icon name="clock-o" class="timeIcon" />
        <span>{{ details.serveTime }}分钟</span>
      </p>
      <p class="project">{{ details.serveIntroduce }}</p>
    </div>
    <div class="serve-select-div">
      <p @click="serveUserSelect" class="attendantInfo">
        <span>预约技师</span>
        <span class="info" :style="{ color: serve_name ? '#000' : 'red' }"
          >{{ serve_name || "请选择" }}
          <img
            class="serveUrl"
            :src="serve_url"
            v-if="serve_url"
            alt=""/><van-icon name="arrow"
        /></span>
      </p>
      <p @click="serveTimeSelect" class="attendantInfo">
        <span>预约时间</span>
        <span class="info" :style="{ color: appointment_time ? 'red' : 'red' }">
          {{ appointment_time_title }}{{ appointment_time || "请选择" }}
          <van-icon name="arrow"
        /></span>
      </p>
    </div>
    <div class="tabs-div">
      <van-tabs
        v-model="active"
        line-height="2"
        title-active-color="#ee0a24"
        title-inactive-color="#000"
      >
        <van-tab title="项目描述"
          ><div v-html="details.description" class="html-div"></div
        ></van-tab>
        <van-tab title="预约通知"
          ><div v-html="details.reservationInstructions" class="html-div"></div
        ></van-tab>
      </van-tabs>
    </div>
    <div class="servePrice-div">
      <p @click="confirmPurchase(1)">会员购 ￥{{ details.serveVipPrice }}</p>
      <p @click="confirmPurchase(2)">原价购 ￥{{ details.servePrice }}</p>
    </div>
    <!-- 选择服务时间 -->
    <van-popup
      v-model="serveSelectTimeShow"
      closeable
      position="bottom"
      :style="{ minHeight: '50%' }"
      round
    >
      <div class="serveSelectTimePopup">
        <van-tabs
          v-model="timeActive"
          line-height="2"
          title-active-color="#ee0a24"
          title-inactive-color="#000"
          @change="appointment_select_time = ''"
        >
          <van-tab
            v-for="(item, index) in serveSelectTime"
            :key="index"
            :title="item.appointmentDateTitle"
            :name="item.appointmentDate"
          >
            <div class="timeList">
              <span
                :class="[
                  'time-li',
                  time == appointment_select_time ? 'time-li-select' : ''
                ]"
                @click="appointment_select_time = time"
                v-for="time in item.appointmentTime"
                :key="time"
                >{{ time }}</span
              >
            </div>
          </van-tab>
        </van-tabs>
        <div class="confirm-div">
          <p class="tips">实际到达时时间可能会有30分钟浮动</p>
          <p class="confirmBtn" @click="confirmSelectTime">确认预约</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getLsServeDetailOut } from "@/api/index";
import { getLsArtificerAppointmentTimeOutList, chooseArtificer } from "@/api/service";
import { getweekday, getToDay } from "@/utils/index";
import { wxConfig } from '../../utils/index';
import AMapLoader from "@amap/amap-jsapi-loader";
import Location from "@/utils/location";

export default {
  data() {
    return {
      timeActive: 0,
      serveSelectTime: [],
      active: 0,
      details: "",
      serve_name: "",
      serve_url: "",
      serve_id: "",
      appointment_time: "",
      appointment_time_title: "",
      appointment_select_time: "",
      serveSelectTimeShow: false,
      lng:"",
      lat:""
    };
  },
  activated() {
    // console.log("2222",this.$route.query)
    this.getLsServeDetailOut();
    // 防止出现问题，回到这个页面时候全部清掉
    sessionStorage.removeItem("orderSelectAddress")
    sessionStorage.removeItem("couponInfo");
    // 默认获取第一个技师
    if(!sessionStorage.getItem("attendantInfo")){
      this.getMap();
    }else{
      sessionStorage.removeItem("attendantInfo");
    }
  },
  created() {},
  methods: {
    getMap() {
      let _this = this;
      AMapLoader.load({
        plugins: ["AMap.Geolocation"]
      }).then(AMap => {
        _this.$store.commit("loadingControl",true)
        Location.getCurrentLocation(
          AMap,
          result => {
            _this.lng = result.pois[0].location.lng;
            _this.lat = result.pois[0].location.lat;
            _this.getchooseArtificer()
          },
          () => {
            console.log("定位失败");
            _this.getchooseArtificer();
          }
        );
      });
    },
    getchooseArtificer(){
      let params = {
        serveId: this.$route.query.id,
        pageIndex: 1,
        pageSize: 10,
        latitude: this.lat || "39.99735",
        longitude: this.lng || "116.480073",
      }
      chooseArtificer(params).then(res => {
        res.data.records.forEach(item => (item.showPopover = false));
        this.$store.commit("loadingControl",false)
        let firstAttendantInfo = res.data.records[0];
        this.serve_name = firstAttendantInfo.nickName;
        this.serve_id = firstAttendantInfo.artificerId;
        this.serve_url = firstAttendantInfo.avatar;
        this.appointment_time = firstAttendantInfo.earliestAppointmentTime;
        this.timeActive = firstAttendantInfo.earliestAppointmentDate;
        this.appointment_time_title =
          getToDay(firstAttendantInfo.earliestAppointmentDate) +
          "天" +
          getweekday(firstAttendantInfo.earliestAppointmentDate);
      });
    },
    // 点击下单购买 1 会员 2 原价
    confirmPurchase(type) {
      if (
        this.getwxcode("/projectDetails", { id: this.$route.query.id })
      ){
        if (!this.serve_id) {
          this.$toast("请选择技师");
        } else if (!this.appointment_time) {
          this.$toast("请选择预约时间");
        } else {
          let data = {
            type: type,
            serve_name: this.serve_name,
            serve_id: this.serve_id,
            serve_url: this.serve_url,
            appointment_time: this.appointment_time,
            id: this.$route.query.id,
            appointment_time_title: this.appointment_time_title,
            timeActive: this.timeActive
          };
          this.$router.push({
            path: "/order/confirmOrder",
            query: data
          });
        }
      }
    },
    // 确认选择预约时间
    confirmSelectTime() {
      if (!this.appointment_select_time) {
        this.$toast("请选择预约时间");
      } else {
        this.appointment_time = this.appointment_select_time;
        this.appointment_time_title =
          getToDay(this.timeActive) + "天" + getweekday(this.timeActive);
        this.appointment_select_time = "";
        this.serveSelectTimeShow = false;
      }
    },
    // 服务人员点击
    serveUserSelect() {
      if (
        this.getwxcode("/selectAttendant", { serve_id: this.$route.query.id })
      ) {
        let params =  JSON.parse(sessionStorage.getItem('selectCityAddress'))
        let query = {
          serve_id: this.$route.query.id,
        }
        if(params){
          query.lat = params.cityInfo.lat
          query.lng = params.cityInfo.lng
        }
        this.$router.push({
          path: "/selectAttendant",
          query: query
        });
      }
    },
    // 服务时间点击
    serveTimeSelect() {
      if (!this.serve_id) {
        this.$toast("请先选择服务人员");
      } else {
        getLsArtificerAppointmentTimeOutList({
          artificerId: this.serve_id
        }).then(res => {
          res.data.forEach(item => {
            item.appointmentDateTitle =
              getToDay(item.appointmentDate) +
              "天" +
              getweekday(item.appointmentDate);
            item.appointmentTime =item.appointmentTime!=''? item.appointmentTime.split(","):[];
          });
          this.serveSelectTime = res.data;
          this.appointment_select_time = this.appointment_time;
          this.serveSelectTimeShow = true;
        });
      }
    },
    // 获取详情
    getLsServeDetailOut() {
      const cityCode = localStorage.getItem("cityCode") || "110100"
      let data = {
        serveId: this.$route.query.id,
        cityCode: cityCode,
      };
      getLsServeDetailOut(data).then(res => {
        this.details = res.data;
        res.data.description = decodeURI(res.data.description).replace(
          /<img/g,
          '<img style="max-width: 100%;"'
        );
        res.data.reservationInstructions = decodeURI(
          res.data.reservationInstructions
        ).replace(/<img/g, '<img style="max-width: 100%;"');
        let data = {
            title: this.details.serveName,
            desc: this.details.serveIntroduce,
            link:localStorage.getItem("shareCode")?window.location.href + `&shareCode=${localStorage.getItem("shareCode")}`:window.location.href,
            imgUrl: this.details.serveImgUrl,
        }
        data.link = data.link+"&from=pageShare"
        wxConfig(data)
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == "/") {
      Object.assign(this.$data, this.$options.data.call(this));
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    if (from.path == "/selectAttendant") {
      next(vm => {
        if (sessionStorage.getItem("attendantInfo")) {
          let attendantInfo = JSON.parse(
            sessionStorage.getItem("attendantInfo")
          );
          vm.serve_name = attendantInfo.nickName;
          vm.serve_id = attendantInfo.artificerId;
          vm.serve_url = attendantInfo.avatar;
          vm.appointment_time = attendantInfo.earliestAppointmentTime;
          vm.timeActive = attendantInfo.earliestAppointmentDate;
          vm.appointment_time_title =
            getToDay(attendantInfo.earliestAppointmentDate) +
            "天" +
            getweekday(attendantInfo.earliestAppointmentDate);
          
        }
      });
    } else {
      next();
    }
  }
};
</script>

<style lang="less" scoped>
.projectDetails {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .serveImg {
    max-width: 100%;
    height: auto;
  }
  .tabs-div {
    flex: 1;
  }
  .information-div {
    padding: 0.4rem;
    .name {
      font-size: 0.4rem;
      font-weight: bold;
      margin-bottom: 0.2rem;
    }
    .price-p,
    .time {
      display: flex;
      align-items: center;
    }
    .time {
      color: gray;
      .timeIcon {
        margin-right: 5px;
        padding: 0.2rem 0;
      }
    }
    .price {
      font-size: 0.4rem;
      font-weight: 600;
      color: orange;
    }
    .VipPrice {
      display: flex;
      align-items: center;
      margin-left: 0.4rem;
      font-size: 0.28rem;
      background: rgb(248, 188, 78);
      padding-right: 0.2rem;
      border-radius: 0.4rem;
      .vipIcon {
        height: 0.45rem;
      }
    }
    .project {
      font-size: 0.28rem;
      color: #ccc;
    }
  }
  .html-div {
    border-top: solid 1px #d1d0d0;
    padding: 0.4rem;
  }
  .serve-select-div {
    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem;
      &:last-child {
        border-top: solid 1px #ececec;
      }
      span {
        flex: 1;
        &:last-child {
          text-align: right;
        }
      }
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .serveUrl {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        padding: 0 5px;
      }
    }
  }
  .servePrice-div {
    position: sticky;
    bottom: 0;
    display: flex;
    z-index: 10;
    p {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-size: 0.36rem;
      padding: 0.4rem 0;
      &:first-child {
        background: #000;
      }
      &:last-child {
        background: red;
      }
    }
  }
  .serve-select-div {
    border-top: 8px solid #ebebeb;
    border-bottom: 8px solid #ebebeb;
  }
  .serveSelectTimePopup {
    min-height: 50vh;
    padding-top: 0.8rem;
    display: flex;
    flex-direction: column;
    .van-tabs {
      flex: 1;
    }
    .timeList {
      display: flex;
      flex-wrap: wrap;
      max-height: 6rem;
      overflow: auto;
      .time-li {
        background: #f1f1f1;
        padding: 8px 0;
        width: 24%;
        text-align: center;
        border-radius: 0.4rem;
        margin-top: 0.4rem;
        &:not(:nth-child(4n)) {
          margin-right: 1%;
        }
      }
      .time-li-select {
        background: rgb(238, 10, 36);
        color: #fff;
      }
    }
    .confirm-div {
      padding: 0.3rem;
      text-align: center;
      .tips {
        font-size: 0.24rem;
        margin-bottom: 0.3rem;
        color: orange;
      }
      .confirmBtn {
        font-size: 0.38rem;
        font-weight: bold;
        color: #fff;
        background: rgb(238, 10, 36);
        padding: 15px 0;
        border-radius: 0.6rem;
      }
    }
  }
}
</style>
